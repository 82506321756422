import React, { useCallback, useContext,useEffect,useMemo,useState } from 'react'
import useFetchImgRecette from '../../hook/useFetchImgRecette'
import useFetchRecettte from '../../hook/useFetchRecette'
import { useParams, useSearchParams } from 'react-router-dom'
import Loading from '../../composants/Loading'
import Tag from '../../composants/Tag'
import IngredientRond from '../../composants/ingredient/IngredientRond'
import Etape from '../../composants/Etape'
import { authcontext } from '../../contexte/auth/Authcontext'
import IngredientModify from '../../composants/modifier recette/IngredientModify'
import EtapeModify from '../../composants/modifier recette/EtapeModify'
import Addingredient from '../../composants/modifier recette/Addingredient'
import { mesageContext } from '../../contexte/mesage/MesageContext'
import Message from '../../class/Message'
import BtnBascule from '../../composants/BtnBascule.jsx'


export default function ModifyRecette() {
    const {idRecette} = useParams()
    const FetchRecette = useFetchRecettte(idRecette) 
    const img = useFetchImgRecette(idRecette)
    const {authFetch} = useContext(authcontext)
    const  {addMessage} = useContext(mesageContext)
    
    const [timerDescription,setTimerDescription] = useState(null)
    

    const [recette,setRecette] = useState(false)
    const [toggleAddIngredient,setToggleIngredient] = useState(false)
    const [recetteActive,setRecetteActive] = useState(false)
    const [recetteDesert,setRecetteDesert] = useState(false)

    console.log("recetteActive", recette.active)

    useEffect(()=>{
        console.log(FetchRecette)
        setRecette(FetchRecette)
        setRecetteActive(FetchRecette.active)
        setRecetteDesert(FetchRecette.desert)
    },[FetchRecette])

    const modification =useMemo(()=>(new Map()),[])
    const timerIngredientMap = useMemo(()=>(new Map()),[]) 
    const timerEtapeMap = useMemo(()=>(new Map()),[])
    
    const save = ()=>{
        const modif = Array.from(modification.values())
        authFetch.patchJson("/recette",{idRecette:idRecette,modification:modif}).then(()=>{
            addMessage(new Message("Reccette modifier avec succès","info",5000))
            modification.clear()
        }).catch((err)=>{
            console.error(err)
            addMessage(new Message("Une erreur est survenue","error"))
        })
    }

    const modifyDescription = useCallback((e)=>{
        
        clearTimeout(timerDescription)
        const value = e.target.value

        setRecette((prevRecette)=>{
            return {...prevRecette,description:value}
        })
        setTimerDescription(setTimeout(()=>{

        modification.set('description',{type:'description',action:'modify',value:value})
        console.log(modification)
   },400)) 
       
    },[timerDescription])
 
    const modifyIngredient = useCallback((ingredient)=>{
        clearInterval(timerIngredientMap.get(ingredient.idIngredient))
        setRecette((prevRecette)=>{
            let copyRecette = {...prevRecette}
            let ingredientIndex = copyRecette.ingredients.findIndex((ingre)=>(ingre.idIngredient === ingredient.idIngredient))
            copyRecette.ingredients[ingredientIndex] = ingredient
            return copyRecette
            
        })

        timerIngredientMap.set(ingredient.idIngredient,setTimeout(()=>{
            if(modification.get('ingredient/'+ingredient.idIngredient)?.action === 'add'){
                modification.set('ingredient/'+ingredient.idIngredient,{type:'ingredient',action:'add',value:ingredient})
            }else{
                modification.set('ingredient/'+ingredient.idIngredient,{type:'ingredient',action:'modify',value:ingredient})
            }
            console.log(modification)
        },250))
    },[])

    const  addIngredient = useCallback( (ingredient)=>{
        clearInterval(timerIngredientMap.get(ingredient.idIngredient))

        setRecette((prevRecette)=>{
            let copyRecette = {...prevRecette}
            copyRecette.ingredients.push(ingredient)
            return copyRecette
            
        })

        timerIngredientMap.set(ingredient.idIngredient,setTimeout(()=>{
            if(modification.get('ingredient/'+ingredient.idIngredient)?.action === 'delete'){
                modification.delete('ingredient/'+ingredient.idIngredient)
            }else{
                modification.set('ingredient/'+ingredient.idIngredient,{type:'ingredient',action:'add',value:ingredient})
            }
                
        },250))
    },[])

    const  deleteIngredient = useCallback( (ingredient)=>{
        clearInterval(timerIngredientMap.get(ingredient.idIngredient))
        console.log(ingredient)
        setRecette((prevRecette)=>{
            let copyRecette = {...prevRecette}
            let ingredientIndex = copyRecette.ingredients.findIndex((ingre)=>(ingre.idIngredient === ingredient.idIngredient))
            copyRecette.ingredients.splice(ingredientIndex,1)
            return copyRecette
            
        })

        if(modification.get('ingredient/'+ingredient.idIngredient)?.action === 'add'){
            modification.delete('ingredient/'+ingredient.idIngredient)
        }else{
            modification.set('ingredient/'+ingredient.idIngredient,{type:'ingredient',action:'delete',value:ingredient})
        } 
        console.log(modification)
        
    },[])

    const modifyEtape = useCallback((etape)=>{

        clearInterval(timerEtapeMap.get(etape.id))

        setRecette((prevRecette)=>{
            let copyRecette = {...prevRecette}
            let etapeIndex = copyRecette.etapes.findIndex((etap)=>(etap.id === etape.id))
            copyRecette.etapes[etapeIndex] = etape
            return copyRecette
        })

        timerEtapeMap.set(etape.id,setTimeout(()=>{
            if(modification.get('etape/'+etape.id)?.action === 'add'){
                modification.set('etape/'+etape.id,{type:'etape',action:'add',value:etape})
            }else{
                modification.set('etape/'+etape.id,{type:'etape',action:'modify',value:etape})
            }
            console.log(modification)
        },250))


    },[])

    const toggleActive = (active)=>{
        setRecetteActive(active)
        if(modification.has('active')) return modification.delete('active')
        modification.set('active',{type:'activeToggle',action:'toggle',value:active})
        console.log(modification)
    }

    const toggleDesert = (desert)=>{
        setRecetteDesert(desert)
        if(modification.has('desert')) return modification.delete('desert')
        modification.set('desert',{type:'desertToggle',action:'toggle',value:desert})
        console.log(modification)
    }

    const addEtape = useCallback((etape)=>{

        clearInterval(timerEtapeMap.get(etape.id))

        setRecette((prevRecette)=>{
            let copyRecette = {...prevRecette}
            let etapeIndex = copyRecette.etapes.findIndex((etap)=>(etap.id === etape.id))
            copyRecette.etapes.push(etape)
            return copyRecette
        })

        timerEtapeMap.set(etape.id,setTimeout(()=>{   
            modification.set('etape/'+etape.id,{type:'etape',action:'add',value:etape})
            console.log(modification)
        },250))


    },[])



    if(recette === false){
        return <Loading/>
    }

  return (
      <div className='contenair contenair--tall contenair--column contenair--centre'>
        <div className='grid grid__4c  grid__8r constenaire--centre recette recette--cuisiner recette--cuisiner--modify'>
            <div className='recette__img recette__img--cuisiner contenair contenair--centre'>
                {img === false ? <Loading/> : <img alt='img ilustration recette' src={URL.createObjectURL(img)}/>}
            </div>
            <div className='contenair  recette__info recette__info--cuisiner'>
                      <div className=' recette__info--cuisiner__warpper contenair contenair--column'>
                        <div className='contenair contenair--centre recette__name'>{recette.name}</div>
                        <div className='contenair contenair--centre contenair--no-wrap  recette__info__tags'>
                            {recette.categories.map((categorie)=>(<Tag key={categorie.id} tag={categorie} color={categorie.color}/>))}
                        </div>
                        <div className='recette__duration'>temps : {recette.duration}</div>
                        <div className='contenair contenair--centre  recette__description'>
                            <textarea name="description" id="description" cols="70" rows="5" onChange={(e)=>{modifyDescription(e)}} value={recette.description}></textarea>
                        </div>
                      </div>
                    <div className='contenair recette__option--modify'>
                        <div className='contenair contenair--centre'>
                            <span>recette activée : </span>
                            <BtnBascule initState = {recetteActive} onClick={toggleActive}/>
                        </div>
                        <div className='contenair contenair--centre'>
                            <span>recette desert : </span>
                            <BtnBascule initState = {recetteDesert} onClick={toggleDesert}/>
                        </div>
                    </div>
            </div>

                <div className='contenair contenair--scrollY recette__ingredients--cuisiner'>
                    {toggleAddIngredient ? <Addingredient close={()=>{setToggleIngredient(false)}} add={addIngredient}/> :
                        <div className='contenair'>
                            {recette.ingredients.map((ingredient)=>(<IngredientModify key={'ingredient/'+ingredient.idIngredient} ingredient={ingredient} del={deleteIngredient} modify={modifyIngredient}/>))}
                            
                            <div onClick={()=>{setToggleIngredient(true)}} className={ 'btn ingredient ingredient--rond contenair contenair--centre contenair--space-evently'} >
                                <img src= "/add.png" alt="ajouter" />
                                <div className='ingredient__nom ' >
                                    Ajouter
                                </div>
                            </div>


                        </div>
                    }
                </div>
                
                    
                        <div className='recette__etapes--cuisiner contenair contenair--space-evently contenair--scroll'>
                            {recette.etapes.map((etape)=>(<EtapeModify modify={modifyEtape} etape={etape} key={etape.id} idRecette={recette.id} classWarper={'recette__etape recette__etape--cuisiner'} classInstruction={'etape__instruction--cuisiner '}/>))}
                        </div>
        </div>
        <div className='contenair'>
            <button className='btn btn--big btn--green' onClick={()=>{save()}}>Enregistrer</button>
        
        </div>
    </div>
  )
  
}
