function getsemaine(date,n=0){
    const dayone = new Date(date.getFullYear(),0,1)
    const day = dayone.getDay()
    const firstTursday = dayone - 24*60*60*1000*(day-1)
    const dateint = Math.floor((date - firstTursday)/(24*60*60*1000))
    const result = Math.ceil(dateint/7)
    if(result + n > 52){ 
       return result + n - 52
    }
    return result + n 
}

module.exports = getsemaine 